export default class RealtimeConnection {
    constructor(apiKey, onEventReceived = () => {}, onConnectionEstablished = () => {}, onError = () => {}) {
      this.apiKey = apiKey;
      this.onEventReceived = onEventReceived;
      this.onConnectionEstablished = onConnectionEstablished;
      this.onError = onError;
      this.pc = null;
      this.dc = null;
      this.audioEl = null;
    }
  
    async init() {
      try {
        // Create a peer connection
        this.pc = new RTCPeerConnection();
  
        // Set up to play remote audio from the model
        this.audioEl = document.createElement("audio");
        this.audioEl.autoplay = true;
        this.pc.ontrack = (e) => {
          this.audioEl.srcObject = e.streams[0];
        };
  
        // Add local audio track for microphone input in the browser
        const ms = await navigator.mediaDevices.getUserMedia({ audio: true });
        ms.getTracks().forEach((track) => this.pc.addTrack(track, ms));
  
        // Set up data channel for sending and receiving events
        this.dc = this.pc.createDataChannel("oai-events");
        this.dc.addEventListener("message", (e) => {
          const realtimeEvent = JSON.parse(e.data);
          this.onEventReceived(realtimeEvent);
        });
  
        // Start the session using the Session Description Protocol (SDP)
        const offer = await this.pc.createOffer();
        await this.pc.setLocalDescription(offer);
  
        const baseUrl = "https://api.openai.com/v1/realtime";
        const model = "gpt-4o-realtime-preview-2024-12-17";
  
        const response = await fetch(`${baseUrl}?model=${model}`, {
          method: "POST",
          body: this.pc.localDescription.sdp,
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/sdp",
          },
        });
  
        if (!response.ok) {
          throw new Error(`SDP Exchange failed with status ${response.status}`);
        }
  
        const answerSdp = await response.text();
        const answer = { type: "answer", sdp: answerSdp };
        await this.pc.setRemoteDescription(answer);
  
        this.dc.addEventListener("open", () => {
          this.onConnectionEstablished();
          // add tools to update relay plan
          this.addToolsToSession();
        });
  
        console.debug("🤝 WebRTC connection established.");
      } catch (error) {
        console.error("🛑 RealtimeConnection Init Error:", error);
        this.onError(error);
      }
    }

    addToolsToSession() {
      const sessionUpdateEvent = {
        type: "session.update",
        session: {
            tools: [
                {
                    type: "function",
                    name: "complete_step",
                    description: "Completes a specific step in the process.",
                    parameters: {
                        type: "object",
                        properties: {
                            step: { type: "integer" }
                        },
                        required: ["step"]
                    }
                },
                // {
                //     type: "function",
                //     name: "clear_step",
                //     description: "Clears a specific step in the process.",
                //     parameters: {
                //         type: "object",
                //         properties: {
                //             stepNumber: { type: "integer" }
                //         },
                //         required: ["stepNumber"]
                //     }
                // },
                {
                    type: "function",
                    name: "clear_all",
                    description: "Clears all the steps."
                }
            ]
        }
    };
    this.sendEvent(sessionUpdateEvent);
    }

    addToConversation(message) {
      const conversationEvent ={
        "type": "conversation.item.create",
        "item": {
            "type": "message",
            "role": "user",
            "content": [
                {
                    "type": "input_text",
                    "text": message
                }
            ]
        }
      };
      this.sendEvent(conversationEvent);
    }

    askForResponse(message) {
      const conversationEvent ={
        "type": "response.create",
        "response": {
          "instructions": message,
        }
      };
      this.sendEvent(conversationEvent);
    }
  
    sendEvent(event) {
      if (this.dc && this.dc.readyState === "open") {
        this.dc.send(JSON.stringify(event));
      } else {
        console.warn("Data channel is not open. Cannot send event:", event);
      }
    }
  
    close() {
      if (this.dc) {
        this.dc.close();
      }
      if (this.pc) {
        this.pc.close();
      }
      if (this.audioEl) {
        this.audioEl.srcObject = null;
      }
      console.debug("RealtimeConnection closed.");
    }
  }